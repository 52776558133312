import React from 'react'
import '../../pages/Tokenomics/Tokenomics.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const Usecases = () => {
  return (
    <div className="usecases">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Usecases of SNPTW</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                    <ul>
                        <li>Addressing remote work</li>
                        <p>
                            All of the current issues around remote work could potentially be resolved via our<br/> 
                            SnapitWorld. With the help of digital avatars, managers may interact with staff<br/> 
                            members virtually, interpret their body language, and maintain face-to-face<br/> 
                            communication. Additionally, by monitoring the team in a virtual office, the<br/> 
                            employer can eliminate issues like goldbricking and time theft at work.
                        </p>
                        <li>Excellent resource for healthcare professionals</li>
                        <p>
                            For medical personnel and experts who were previously unable to visit patients<br/> 
                            because of geographic restrictions, a metaverse is a useful tool. They can<br/> 
                            communicate with the patient and get a clear picture of their health in the Metaverse,<br/> 
                            a virtual environment.
                        </p>
                        <li>Increasing the excitement of online gaming</li>
                        <p>
                            To enable play-to-earn gaming, the majority of Metaverse games available today are<br/>
                            decentralized and have an integrated economic model. These games let players<br/> 
                            exchange NFTs, or in-game currency, for other in-game items. In addition, gamers<br/> 
                            find it intriguing to imagine themselves exploring the expansive virtual world as<br/> 
                            avatars.
                        </p>
                        <li>Online workspaces and educational environment</li>
                        <p>
                            Platforms for video conferences have grown in popularity as well, especially for<br/> 
                            online learning, remote employment, and other work-from-home options. Because<br/> 
                            of the real-time audio and visual interaction, these platforms are unable to deliver a<br/> 
                            captivating and engaging experience.<br/>
                            To overcome this drawback, we offer our consumers a more captivating experience<br/> 
                            with its three-dimensional avatars, immersive meetings, and graphically rich virtual<br/> 
                            environment.
                        </p>
                        <li>eCommerce usage to empower cryptos and NFTs</li>
                        <p>
                            E-commerce business owners can interact with merchants in a virtual space and<br/> 
                            perform trading formalities such as product inspection, negotiations, and finalizing<br/> 
                            deals. Plus, they can better influence customers using interactive and realistic<br/> 
                            marketing content rather than relying on digital marketing tactics.<br/>
                            With many new business concepts, Our snapitworld ecosystem also complements<br/> 
                            the creation, ownership, and trading of digital assets and tokenized versions of real-<br/>
                            world assets to empower cryptos and NFTs.
                        </p>
                        <li>Advertising brands </li>
                        <p>
                            Businesses can use billboards to advertise their goods, services, and events next to<br/> 
                            or within highly trafficked land parcels. Brands may also arrange merchandise and<br/> 
                            arrange communal events as a means of interacting with customers.
                        </p>
                        <li>Digital Collectibles for creators</li>
                        <p>
                            We anticipate that users will disseminate, publish, and gather unique digital assets<br/> 
                            that their creators have issued on the blockchain. These digital assets will be traded<br/> 
                            inside this world. You can buy collectibles during gaming in the virtual world like<br/> 
                            helmets, accessories, shoes, etc.
                        </p>
                        <li>Social media platforms</li>
                        <p>
                            Communities that assemble in chat rooms, online forums, or even other centralized<br/> 
                            multiplayer games may be transferred to our SNAPIT world. It also offers offline<br/>
                            communities a place to congregate.
                        </p>
                    </ul><br/>
                    <div className="image">
                        <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/usecase.png' alt='Usecases' title='Usecases' fluid style={{width: '500px'}} />
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Usecases