import React from "react";
import '../../pages/Tokenomics/Tokenomics.css'
import { Routes, Route } from 'react-router-dom'
import Sidebar from "../../components/Sidebar/Sidebar";
import WrappedSnapit from '../../components/WrappedSnapit/WrappedSnapit';
import SnapitToken from '../../components/SnapitToken/SnapitToken';
import Usecases from '../../components/Usecases/Usecases';
import Governance from '../../components/Governance/Governance';
import AutomatedMarket from '../../components/AutomatedMarket/AutomatedMarket';
import Tokenomics from '../../components/Tokenomics/Tokenomics'
import Conclusion from "../../components/Conclusion/Conclusion";
import { VscThreeBars } from "react-icons/vsc";


const Whitepaper = () => {
  return (
    <div className="tokenomics ">
      <button className="mobile-toggler-button"><VscThreeBars /></button>
      <Sidebar  />
      <div className="container-overrall">
        <Routes>
          <Route path="/" element={<Tokenomics />} />
          <Route path="/Tokenomics" element={<Tokenomics />} />
          <Route
            path="/WrappedSnapitToken-SNPT"
            element={<WrappedSnapit />}
          />
          <Route
            path="/SNAPITTOKEN-SNPTW"
            element={<SnapitToken />}
          />
          <Route path="/UsecasesOfSNPT" element={<Usecases />} />
          <Route path="/GovernanceOfSNPT" element={<Governance />} />
          <Route
            path="/MakerStrategyForSNPT"
            element={<AutomatedMarket />}
          />
          <Route path="/Conclusion" element={<Conclusion /> } />
        </Routes>
      </div>
    </div>
  );
};

export default Whitepaper;
