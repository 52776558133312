import React from 'react'
import './App.css'
import NavBar from './components/NavBar/NavBar'
import Tokenomics from './pages/Tokenomics/Tokenomics'
import $ from "jquery"
import { useState, useEffect } from "react";




const App = () => {

  useEffect(() => {
    $(document).ready(function () {
      $(".mobile-toggler-button").click(function () {
        $(".sidebar").css("width", "300px");
      });
      $(".mobile-toggler-close-button").click(function () {
        $(".sidebar").css("width", "0px");
      });
    });
    if (window.location.pathname === "/") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".Tokenomics").addClass("sidebar-item-active");
    }
    if (window.location.pathname === "/Tokenomics") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".Tokenomics").addClass("sidebar-item-active");
    }
    if (window.location.pathname === "/WrappedSnapitToken-SNPT") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".Wrapped").addClass("sidebar-item-active");
    }
    if (window.location.pathname === "/SNAPITTOKEN-SNPTW") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".SNAPIT").addClass("sidebar-item-active");
    }
    if (window.location.pathname === "/UsecasesOfSNPT") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".Usecases").addClass("sidebar-item-active");
    }
    if (window.location.pathname === "/GovernanceOfSNPT") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".Governance").addClass("sidebar-item-active");
    }
    if (window.location.pathname === "/MakerStrategyForSNPT") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".Strategy").addClass("sidebar-item-active");
    }
    if (window.location.pathname === "/Conclusion") {
      $(".sidebar-item").removeClass("sidebar-item-active");
      $(".Conclusion").addClass("sidebar-item-active");
    }
  });
  return (
    <div className="app">
      <NavBar />
      <Tokenomics />
    </div>
  )
}

export default App